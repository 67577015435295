import React from "react"
import SEO from "../../../components/seo"
import { medications } from "../index"
import Tile from "../../../components/tile"
import Layout from "../../../components/layout"

const index = () => {
	return (
		<Layout>
			<SEO title="Medications used to treat lupus" />
			<h1>Medications used to treat lupus</h1>
			<p>
				While there is no silver bullet and no single medication is helpful in
				all cases of lupus, there are several classes of medications that have
				shown to assist with managing the symptoms associated with lupus, either
				directly or by reducing the body’s autoimmune response.
			</p>
			<p>
				As with all medications, each of these have side effects. You need to
				discuss with your doctor and find the right medication(s) that provides
				the most symptomatic relief alongside the best side effect profile - not
				everyone gets the side effects and hopefully you don’t either!
			</p>
			<p>
				The right medication or combination of medications will be different for
				everyone and may involve some trial and error. We recommend that you
				record your response to each medication (the tools listed in the next
				section are helpful in this) and continue discussing this with your
				doctor until you find what is best for you.
			</p>
			<p>
				The combination of medications for your lupus can also change over time.
			</p>
			<div className="grid grid-cols-1 gap-4">
				{medications.map(({ name, id }) => (
					<Tile
						key={id}
						text={name}
						path={`/treatment-options/medications/${id}`}
					/>
				))}
			</div>
		</Layout>
	)
}

export default index
